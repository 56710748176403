import React from 'react';
import ReactDOM from 'react-dom';
import * as bodyPix from '@tensorflow-models/body-pix';
import * as tf from '@tensorflow/tfjs';
import { NonMaxSuppressionV3 } from '@tensorflow/tfjs';

//Obtiene la imagen de la webcam, detecta los cuerpos y le aplica un recorte a un canvas para eliminar el fondo
class BodyPixEnabledWebCam extends React.Component {
    //Declaramos las variables iniciales
    constructor(props) {
        super(props);
        this.state = {
            net: null,
            style: {
                display: 'none',
            }
        }
        this.videoTag = React.createRef();
        this.canvasTag = React.createRef();
        this.drawBody = this.drawBody.bind(this);
        this.detectBody = this.detectBody.bind(this);
    }

    componentDidMount() {

        //Obtenemos el acceso a la webcam
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(stream => {
                this.videoTag.current.srcObject = stream
                console.dir(this.videoTag)

                this.videoTag.current.onloadeddata = (event) => {
                    if (this.state.net == null) {
                        console.dir(this.props.bodypixConfig);
                        bodyPix.load(this.props.bodypixConfig)
                            .catch(error => {
                                console.log(error);
                                this.props.onError();
                            })
                            .then(objNet => {
                                //Cambiamos el estado del state y detectamos el cuerpo de la webcam
                                this.setState({ net: objNet });
                                this.detectBody();
                            });
                    }
                };
            })
            .catch((error) => { console.log(error) });
    }

    //Función para detectar los cuerpos dado un vídeo (en este caso, la imagen de la webcam)
    detectBody() {
        if (this.state.net != null) {
            //Segmentamos el video y le pasamos los ajustes deseados
            this.state.net.segmentPerson(this.videoTag.current, {
                //Le indicamos que lo voltee (para leer mejor las letras), que tenga resolución media y que la segmentación (o el borde de recorte) sea de 0.55 (valor de 0-1)
                flipHorizontal: true,
                internalResolution: 'medium',
                segmentationThreshold: 0.55
            }).catch(err => { console.log(err) }).then(personsegmentation => {
                this.drawBody(personsegmentation);
            });
        }
        requestAnimationFrame(this.detectBody);
    }

    //Aplicamos los recortes al canvas para el efecto de eliminar el fondo
    drawBody(personSegmentation) {
        this.canvasTag.current.getContext('2d').drawImage(this.videoTag.current, 0, 0, 512, 384);
        var imageData = this.canvasTag.current.getContext('2d').getImageData(0, 0, 512, 384);
        var pixel = imageData.data;
        for (var p = 0; p < pixel.length; p += 4) {
            if (personSegmentation.data[p / 4] == 0) {
                pixel[p + 3] = 0;
            }
        }

        this.canvasTag.current.getContext('2d').imageSmoothingEnabled = true;
        this.canvasTag.current.getContext('2d').putImageData(imageData, 0, 0);
    }

    render() {
        return (<div style={{ position: "static" }}>
            <video
                id={'videoagente'}
                ref={this.videoTag}
                width={this.props.width}
                height={this.props.height}
                autoPlay
                title={this.props.title}
                style={this.state.style}>
            </video>
            <canvas className="person" id="canvasVidorigen" ref={this.canvasTag} width={this.props.width} height={this.props.height}></canvas>
        </div>)
    }

}

export default BodyPixEnabledWebCam;