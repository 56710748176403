import React, { useRef, useEffect } from "react";
import io from "socket.io-client";
import logo from './assets/logoservinform.png';
import loading from './assets/loadingservcli.gif';
import { useHistory } from 'react-router-dom';

//Conecta el cliente con el administrador que le manda la videollamada
const Room = (props) => {
    //Declaración de variables, useRef es como una “caja” que puedes mantener en una variable mutable en su propiedad .current
    const userVideo = useRef();
    const partnerVideo = useRef();
    const peerRef = useRef();
    const socketRef = useRef();
    const otherUser = useRef();
    const userStream = useRef();
    var peer;
    //History nos permite hacer redirecciones a rutas concretas
    const history = useHistory();
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    //Obtenemos el audio y vídeo de la webcam del cliente
    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
            //Le asignamos a userVideo el stream de la webcam
            userVideo.current.srcObject = stream;
            userStream.current = stream;

            //Sockets para las conexiones de webRTC, roomID es una variable obtenida desde la ruta, en este caso es el identificador de la llamada
            socketRef.current = io.connect("/");
            socketRef.current.emit("join room", props.match.params.roomID);

            //Realiza la llamada al otro usuario gracias al socketRef anterior
            socketRef.current.on('other user', userID => {
                callUser(userID);
                otherUser.current = userID;
            });

            socketRef.current.on("user joined", userID => {
                otherUser.current = userID;
            });

            socketRef.current.on("offer", handleRecieveCall);

            socketRef.current.on("answer", handleAnswer);

            socketRef.current.on("ice-candidate", handleNewICECandidateMsg);
        }).catch(function(err) {
            if (/android/i.test(userAgent)) {
                window.location.href="/navegadorincompatible.html?id=" + props.match.params.roomID;
            }else{
                history.push("/errornavegador/" + props.match.params.roomID);
            }
        });

    }, []);

    //Crea las conexiones con el usuario administrador/agente
    function callUser(userID) {
        peerRef.current = createPeer(userID);
        userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
    }

    //Realiza la conexion webRTC con el iceServers de xirsys
    function createPeer(userID) {
        peer = new RTCPeerConnection({
            iceServers: [
                {
                    urls: ["stun:eu-turn3.xirsys.com"]
                }, {
                    username: "hXJOMNBJpQFkbQPCQgfzhLeKeO3Pv_XtnMc3WKdVCLtLSruS-NqFbFmjb7e9jkKxAAAAAGCvdN1zZXJ2aW5mb3Jt",
                    credential: "9d6d699a-bed6-11eb-bb39-0242ac140004",
                    urls: [
                        "turn:eu-turn3.xirsys.com:80?transport=udp",
                        "turn:eu-turn3.xirsys.com:3478?transport=udp",
                        "turn:eu-turn3.xirsys.com:80?transport=tcp",
                        "turn:eu-turn3.xirsys.com:3478?transport=tcp",
                        "turns:eu-turn3.xirsys.com:443?transport=tcp",
                        "turns:eu-turn3.xirsys.com:5349?transport=tcp"
                    ]
                }
            ]
        });

        peer.onicecandidate = handleICECandidateEvent;
        peer.ontrack = handleTrackEvent;
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);

        return peer;
    }

    function handleNegotiationNeededEvent(userID) {
        peerRef.current.createOffer().then(offer => {
            return peerRef.current.setLocalDescription(offer);
        }).then(() => {
            const payload = {
                target: userID,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            };
            socketRef.current.emit("offer", payload);
        }).catch(e => console.log(e));
    }

    function handleRecieveCall(incoming) {
        peerRef.current = createPeer();
        const desc = new RTCSessionDescription(incoming.sdp);
        peerRef.current.setRemoteDescription(desc).then(() => {
            userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
        }).then(() => {
            return peerRef.current.createAnswer();
        }).then(answer => {
            return peerRef.current.setLocalDescription(answer);
        }).then(() => {
            const payload = {
                target: incoming.caller,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            }
            socketRef.current.emit("answer", payload);
        })
    }

    function handleAnswer(message) {
        const desc = new RTCSessionDescription(message.sdp);
        peerRef.current.setRemoteDescription(desc).catch(e => console.log(e));
    }

    function handleICECandidateEvent(e) {
        if (e.candidate) {
            const payload = {
                target: otherUser.current,
                candidate: e.candidate,
            }
            socketRef.current.emit("ice-candidate", payload);
        }
    }

    function handleNewICECandidateMsg(incoming) {
        const candidate = new RTCIceCandidate(incoming);

        peerRef.current.addIceCandidate(candidate)
            .catch(e => console.log(e));
    }

    //Obtiene el stream del administrador y se lo asigna a partnerVideo
    function handleTrackEvent(e) {
        partnerVideo.current.srcObject = e.streams[0];
    }

    //Corta la conexión
    function closePeer(){
        peer.close();
        peerRef.close();
    }

    return (
        <table class="tableroom">
            <tr>
                <td>
                    <img class="logoservinform" src={logo} /><br></br>
                </td>
            </tr>
            <tr>
                <td>
                    <video class="videonobi" width="640" height="480" autoPlay ref={userVideo} muted poster={loading} playsInline/><br></br>
                    <a onClick={closePeer} href="https://www.tkelevator.com/es-es/" class="btn btn-danger botcolgar">
                        <span class="material-icons" id="spancam">
                            phone_disabled
                        </span>
                        &nbsp; Colgar
                    </a><br></br>
                    <a class="enlace" target="_blank" href="https://www.tkelevator.com/es-es/aviso-legal.html">Aviso legal</a><br></br>
                        <a class="enlace" target="_blank" href="https://www.tkelevator.com/es-es/pol-tica-de-privacidad.html">Política de privacidad</a>
                        <a id="botcerrar" href="https://www.tkelevator.com/es-es/"></a>
                    <video autoPlay class="vidcentral" ref={partnerVideo} playsInline></video>
                </td>
            </tr>
        </table>
    );
};

export default Room;