import React, { useRef, useEffect } from "react";
import io from "socket.io-client";
import loading from './assets/loadingservcli.gif';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import * as bodyPix from '@tensorflow-models/body-pix';
import * as tf from '@tensorflow/tfjs';
import { NonMaxSuppressionV3 } from '@tensorflow/tfjs';

//Conecta el cliente con el administrador que le manda la videollamada, este caso es bidireccional (se ve tanto al admin como al cliente)
const Roombi = (props) => {
    //State lo usamos para el vídeo del administrador, ya que tendrá un efecto para eliminar el fondo
    var state = {
        net: null,
        style: {
            display: 'none',
        }
    }
    //Declaración de variables, useRef es como una “caja” que puedes mantener en una variable mutable en su propiedad .current
    const canvasTag = useRef();
    const userVideo = useRef();
    const partnerVideo = useRef();
    const peerRef = useRef();
    const socketRef = useRef();
    const otherUser = useRef();
    const userStream = useRef();
    var peer;
    var isSafari;
    var isMobile;
    var videorigen;
    var canvasvideoagentereceive;
    var videoagentereceive;
    var logo = 'https://contacto.servinformcrm.com/tke/logotke.png';

    //History nos permite hacer redirecciones a rutas concretas
    const history = useHistory();
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let formData = new FormData();
    formData.append('urlNativ', props.match.params.roomID);

    var aTiempo;
    var SearchAPIURL="https://contacto.servinformcrm.com/tke/checkvalid.php";

    fetch(
        SearchAPIURL,
        {
            method:'POST',
            body: formData
        }
    )
    .then((response)=>response.json())
    .then((response)=>{
        console.log(response);
        aTiempo = response;
        if (aTiempo === 'false') {
            window.location.href="/tiempocaducado.html";
        }
    })

    //Obtenemos el audio y vídeo de la webcam del cliente
    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            audio: {
                optional: [{ googAutoGainControl: false}, {googDucking:true}, {chromeRenderToAssociatedSink:true}]
            }, video: true
        }).then(stream => {
            userVideo.current.srcObject = stream;
            userStream.current = stream;

            socketRef.current = io.connect("/");
            socketRef.current.emit("join room", props.match.params.roomID);

            socketRef.current.on('other user', userID => {
                callUser(userID);
                otherUser.current = userID;
            });

            socketRef.current.on("user joined", userID => {
                otherUser.current = userID;
            });

            socketRef.current.on("offer", handleRecieveCall);

            socketRef.current.on("answer", handleAnswer);

            socketRef.current.on("ice-candidate", handleNewICECandidateMsg);
        }).catch(function(err) {
            if (/android/i.test(userAgent)) {
                window.location.href="/navegadorincompatiblebi.html?id=" + props.match.params.roomID;
            }else{
                history.push("/errornavegadorbi/" + props.match.params.roomID);
            }
        });

    }, []);

    function callUser(userID) {
        peerRef.current = createPeer(userID);
        userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
    }

    function createPeer(userID) {
        peer = new RTCPeerConnection({
            iceServers: [
                {
                    urls: ["stun:eu-turn3.xirsys.com"]
                }, {
                    username: "hXJOMNBJpQFkbQPCQgfzhLeKeO3Pv_XtnMc3WKdVCLtLSruS-NqFbFmjb7e9jkKxAAAAAGCvdN1zZXJ2aW5mb3Jt",
                    credential: "9d6d699a-bed6-11eb-bb39-0242ac140004",
                    urls: [
                        "turn:eu-turn3.xirsys.com:80?transport=udp",
                        "turn:eu-turn3.xirsys.com:3478?transport=udp",
                        "turn:eu-turn3.xirsys.com:80?transport=tcp",
                        "turn:eu-turn3.xirsys.com:3478?transport=tcp",
                        "turns:eu-turn3.xirsys.com:443?transport=tcp",
                        "turns:eu-turn3.xirsys.com:5349?transport=tcp"
                    ]
                }
            ]
        });

        peer.onicecandidate = handleICECandidateEvent;
        peer.ontrack = handleTrackEvent;
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);

        return peer;
    }

    function handleNegotiationNeededEvent(userID) {
        peerRef.current.createOffer().then(offer => {
            return peerRef.current.setLocalDescription(offer);
        }).then(() => {
            const payload = {
                target: userID,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            };
            socketRef.current.emit("offer", payload);
        }).catch(e => console.log(e));
    }

    function handleRecieveCall(incoming) {
        peerRef.current = createPeer();
        const desc = new RTCSessionDescription(incoming.sdp);
        peerRef.current.setRemoteDescription(desc).then(() => {
            userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
        }).then(() => {
            return peerRef.current.createAnswer();
        }).then(answer => {
            return peerRef.current.setLocalDescription(answer);
        }).then(() => {
            const payload = {
                target: incoming.caller,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            }
            socketRef.current.emit("answer", payload);
        })
    }

    function handleAnswer(message) {
        const desc = new RTCSessionDescription(message.sdp);
        peerRef.current.setRemoteDescription(desc).catch(e => console.log(e));
    }

    function handleICECandidateEvent(e) {
        if (e.candidate) {
            const payload = {
                target: otherUser.current,
                candidate: e.candidate,
            }
            socketRef.current.emit("ice-candidate", payload);
        }
    }

    function handleNewICECandidateMsg(incoming) {
        const candidate = new RTCIceCandidate(incoming);

        peerRef.current.addIceCandidate(candidate)
            .catch(e => console.log(e));
    }

    function handleTrackEvent(e) {
        partnerVideo.current.srcObject = e.streams[0];
    }

    function closePeer(){
        peer.close();
        peerRef.close();
    }

    //Detectamos si el navegador es safari para mejor estabilidad en los controles de vídeo
    isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    
    //Detectamos si el cliente está desde un móvil para ajustar una vista u otra
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
        isMobile = true;
    } else {
        isMobile = false;
    }

    return (
        <div>
            <table class="tableroombi">
                <tr>
                    <td>
                        <img class="logoservinform" src={logo} /><br></br>
                    </td>
                </tr>
                <tr>
                    <td>
                        {isSafari ? <video id="partnerVideoId" class="videocetdos" controls autoPlay ref={partnerVideo} poster={loading} playsInline /> : <video  id="partnerVideoId" class="videocetdos" autoPlay ref={partnerVideo} poster={loading} playsInline /> }
                        {isMobile ? <video class="videoclientemobile" autoPlay muted ref={userVideo} poster={loading} playsInline /> : <video class="videocetuno" autoPlay ref={userVideo} muted poster={loading} playsInline />}<br></br>
                        <a onClick={closePeer} href="https://www.tkelevator.com/es-es/" class="btn btn-danger botcolgar">
                            <span class="material-icons" id="spancam">
                                phone_disabled
                            </span>
                            &nbsp; Colgar
                        </a><br></br>
                        <a class="enlace" target="_blank" href="https://www.tkelevator.com/es-es/aviso-legal.html">Aviso legal</a><br></br>
                        <a class="enlace" target="_blank" href="https://www.tkelevator.com/es-es/pol-tica-de-privacidad.html">Política de privacidad</a>
                        <a id="botcerrar" href="https://www.tkelevator.com/es-es/"></a>
                    </td>
                </tr>
            </table>
            <script>
                document.getElementById('partnerVideoId').volume = 1;
            </script>
        </div>
    );
};

export default Roombi;